<template>
    <div class="wrapper">
        <div class="setting">
            <div
                class="setting_body"
                :class="{'setting_body--profile_writers' : $route.name === 'profile_writers'}"
            >
                <slot name="body" />
            </div>

            <footer class="setting_footer">
                <slot name="footer">
                    <custom-button
                        default
                        class="btn-base btn-main button"
                        @on-btn-click="saveChanges()"
                    >
                        Save Changes
                    </custom-button>
                    <custom-button
                        class="btn-base btn-border button"
                        bordered
                        hover
                        @on-btn-click="cancelChanges()"
                    >
                        Cancel
                    </custom-button>
                </slot>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        saveChanges() {
            this.$emit('save')
        },
        cancelChanges() {
            this.$emit('cancel')
        }
    }
}
</script>

<style lang="scss">
    .setting{
        position: relative;
        background: #fff;
        box-shadow: 0 10px 30px 0 rgb(0 0 0 / 8%);
        border-radius: 5px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: $main-gradient;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &_body{
            padding: 30px;
            @media all and (max-width: 380px) {
                padding: 15px;
                padding-top: 30px;
            }
        }
        &_footer{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 30px;
            .btn-base{
                &:first-child {
                    margin-right: 20px;
                }
            }
            @media all and (max-width: 380px) {
                padding: 15px;
            }
        }
    }

    .setting_body--profile_writers{
        padding: 30px 102px 30px 30px !important;
    }
</style>
