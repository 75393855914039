<template lang="html">
    <setting-block-layout
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div class="password_input">
                <input
                    type="text"
                    name="username"
                    autocomplete="username"
                    :value="client.email"
                    style="display: none"
                >

                <div class="password_field">
                    <form-input
                        v-model="old_password"
                        name="old-password"
                        legend="Old Password"
                        prepend-icon="LockIcon"
                        :field.sync="old_password"
                        autocomplete="old-password"
                        :maxlength="20"
                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                        :type="showPassword ? 'text' : 'password'"
                        @clicked="showPassword = !showPassword"
                    />
                </div>

                <div class="password_field">
                    <form-input
                        v-model="$v.password.$model"
                        name="password"
                        legend="New Password"
                        prepend-icon="LockIcon"
                        :field.sync="password"
                        autocomplete="new-password"
                        :maxlength="20"
                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                        :type="showPassword ? 'text' : 'password'"
                        :errors="validationMessage($v.password)"
                        :is-valid="$v.password.$dirty && !$v.password.$anyError"
                        @clicked="showPassword = !showPassword"
                        @input.native="$v.password.$touch()"
                        @blur="$v.password.$touch(); passwordInFocus = false"
                        @focus="passwordInFocus = true"
                    />
                    <div
                        v-if="passwordInFocus && $v.password.$anyError"
                        class="password-validation"
                    >
                        <password-validator-helper :password="password" />
                    </div>
                </div>
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/profile/ProfilePassword/Rules'
import { formMessages } from '@/validation/profile/ProfilePassword/Messages'

import PasswordValidatorHelper from '@/components/PasswordValidatorHelper';
import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import {
    ACTION_CLIENT_UPDATE_PASSWORD
} from '@/store/modules/client/action-types';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout,
        PasswordValidatorHelper
    },
    metaInfo: {
        title: 'Change password'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            showPassword: false,
            readOnly: true,
            passwordInFocus: false,
            passwordTestResults: {
                strength: 0,
                error: false,
                message: '',
                color: ''
            },
            password: '',
            old_password: ''
        }
    },
    computed: {
        ...mapGetters('client', {
            client: 'getterMainClientInfo'
        })
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions({
            updatePassword: ACTION_CLIENT_UPDATE_PASSWORD
        }),
        async onSave() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            try {
                await this.updatePassword({
                    password: this.password,
                    old_password: this.old_password
                })
                eventBus.$emit('showSnackBar', 'Your password has been changed.', 'success');
                this.$router.push({ name: 'profile' })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
            }
        },
        onCancel() {
            this.$router.push({ name: 'profile' })
        }
    }
}
</script>

<style lang="scss" scoped>
    .password_input {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        width: 50%;
        margin: 0 auto;
        .password_field{
            width: 100%;
        }
        @media all and (max-width: 768px){
            width: 100%;
        }
    }
</style>
